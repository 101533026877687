'use client'

import { setGADataLayer } from 'analytics'
import Link from 'next/link'
import { ReactNode } from 'react'

const TrendingPillButton = ({
    href,
    className,
    children,
    trendingTerm,
    term,
}: {
    href: string
    trendingTerm?: string
    term?: string
    className: string
    children: ReactNode
}) => {
    return (
        <Link
            href={href}
            className={className}
            onClick={() => {
                const data = {
                    event: 'search',
                    options: {
                        search_type: term ? 'tag' : 'trending',
                        search_term: trendingTerm || term,
                    },
                }
                setGADataLayer(data)
            }}
        >
            {children}
        </Link>
    )
}

export default TrendingPillButton
