'use client'
import Toggle from '@/components/toggle'
import { SearchTypeContext } from '@/context/search-type'
import UAParserContext from '@/context/ua-parser'
import { trackPageView } from '@/util/analytics'
import { useRouter, useSearchParams } from 'next/navigation'
import { useContext } from 'react'
import { SearchType } from '../types'

export const useGetPathname = (route: string, term: string, type: SearchType): string => {
    const search = useSearchParams()
    const searchParams = search.toString()
    const gifUrl = `${term
        .replace(/stickers|sticker|transparent|clips/gi, '')
        .trim()
        .replace(/\s+/g, '-')}`
    return `${route}/${gifUrl}${type === 'gifs' ? `` : `-${type}`}${searchParams ? `?${searchParams}` : ''}`
}

type Props = {
    className?: string
    initialSearchTerm: string
    routerType: SearchType
    targetPage?: 'search' | 'explore'
}

const SearchToggle = ({ initialSearchTerm, routerType, className, targetPage = 'search' }: Props) => {
    const router = useRouter()
    const { isBot } = useContext(UAParserContext)
    const { setTransitioning } = useContext(SearchTypeContext)
    const targetPageToUse = isBot ? 'explore' : targetPage
    const contentTypes = ['gifs', 'stickers', 'clips'] as const
    const hrefGifs = useGetPathname(`/${targetPageToUse}`, initialSearchTerm, 'gifs')
    const hrefStickers = useGetPathname(`/${targetPageToUse}`, initialSearchTerm, 'stickers')
    const hrefClips = useGetPathname(`/${targetPageToUse}`, initialSearchTerm, 'clips')
    const options = [
        { label: 'GIFs', href: hrefGifs },
        { label: 'Stickers', href: hrefStickers },
        { label: 'Clips', href: hrefClips },
    ]
    return (
        <Toggle
            onClick={(index) => {
                const nextType = contentTypes[index]
                const nextPathname = nextType === 'gifs' ? hrefGifs : nextType === 'stickers' ? hrefStickers : hrefClips
                router.replace(nextPathname)
                setTimeout(() => {
                    trackPageView(nextPathname)
                }, 50)
                setTransitioning(true)
            }}
            options={options}
            className={className}
            index={contentTypes.indexOf(routerType)}
        />
    )
}
export default SearchToggle
